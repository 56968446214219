import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Box from 'components/Box'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Section from 'components/Section'
import Text from 'components/Text'
import AppStoreButtons from 'features/app/components/AppStoreButtons'
import useEventPageVisited from 'hooks/use-event-page-visited'
import styles from './Download.module.scss'

export default function Download() {
  const pageName = 'Download'
  useEventPageVisited(pageName)

  const valueProps = [
    {
      title: 'Better balance. More strength. Less pain.',
      copy: 'Join the 80% of Bold members who feel improvement to their strength and balance after just 6 classes.',
    },
    {
      title: 'Choose from hundreds of classes.',
      copy: 'Build a routine that fits your needs. Take classes any time, anywhere.',
    },
    {
      title: 'Designed and taught by experts.',
      copy: 'Your unique program is designed by our team of physical therapists and coaches.',
    },
    {
      title: 'Track your progress.',
      copy: 'Stay motivated with personalized workout reminders, challenge alerts, and exclusive content sent directly to your phone through notifications.',
    },
  ]

  const faqs = [
    {
      title: 'Do I need a new account for the Bold App?',
      copy: 'No, you won’t need a new account. You’ll sign into the Bold App using the same username as you currently do. This means your class history and badges will transfer over to the app as well.',
    },
    {
      title: 'What devices are supported?',
      copy: 'The Bold app is available on the Apple App Store and on the Google Play Store for both smartphones and tablets.',
    },
    {
      title: 'Will my health provider cover the cost of the app?',
      copy: 'Yes, if your health provider covers Bold, you can enjoy the app at no additional cost to you.',
    },
  ]

  return (
    <Layout className="Download">
      <header className={styles.hero}>
        <Container size="xxlarge">
          <div className={styles['hero--inner']}>
            <Container align="left" flush size="xmedium">
              <div className={styles['hero--copy']}>
                <Heading level={1}>The Bold App is here</Heading>
                <Row size="xmedium">
                  <Text element="p" flush size="large">
                    It’s everything you love about Bold made even easier. Click the button below to
                    download the Bold mobile app.
                  </Text>
                </Row>
                <AppStoreButtons eventLocation={pageName} />
              </div>
            </Container>
            <StaticImage
              src="./images/apps@2x.png"
              alt="Bold app"
              layout="fullWidth"
              placeholder="blurred"
              width={430}
              className={styles['hero--phones']}
            />
          </div>
        </Container>
      </header>
      <Section color="purple">
        <Container size="xxlarge">
          <Heading align="center" levelStyle={3}>
            Your favorite Bold features, now at your fingertips!
          </Heading>
          <div className={styles.boxes}>
            {valueProps.map((item) => (
              <Text element="figure" key={item.title} className={styles.box}>
                <Heading level={3} levelStyle={4}>
                  {item.title}
                </Heading>
                <Text element="p" flush>
                  {item.copy}
                </Text>
              </Text>
            ))}
          </div>
        </Container>
      </Section>
      <Section>
        <Container size="large">
          <Heading align="center" levelStyle={3}>
            Frequently asked questions
          </Heading>
          <Heading />
          {faqs.map((item) => (
            <Row key={item.title} size="xmedium">
              <Box color="white">
                <Heading level={3} levelStyle={4}>
                  {item.title}
                </Heading>
                <Text element="p" flush>
                  {item.copy}
                </Text>
              </Box>
            </Row>
          ))}
        </Container>
      </Section>
    </Layout>
  )
}
